// Raw styling for arbitrary content
.prompt {
  p {
    margin: 0;
    font-size: 1em;
    line-height: 1.8;
  }

  p:empty {
    height: 1.8em;
  }

  p + p {
    margin-top: 0.8em;
  }

  p + blockquote {
    margin-top: 0.8em;
  }

  blockquote + p {
    margin-top: 0.8em;
  }

  section {
    margin: 0.8em 0;
  }

  // Styles for images
  section .image-container {
    text-align: center;
    font-size: 0;
    line-height: 0;

    .image-wrapper {
      display: inline-block;
      font-size: 0;
      line-height: 0;

      picture {
        display: inline-block;
        margin: 0;
        font-size: 0;
        line-height: 0;

        img {
          max-width: 100%;
        }
      }
    }

    .image-wrapper.zoomable {
      position: relative;
      cursor: zoom-in;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 24px;
        font-size: 24px;

        content: '+';
        background: black;
        color: $brand-color;
      }

      &:hover:before {
        color: $hacker-color;
      }
    }
  }

  // Styles for embeds
  section .embed-container {
    .embed-wrapper {
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 56.25%;

      iframe {
        padding: 0;
        margin: 0;
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        vertical-align: middle;
      }
    }
  }

  // Styles for blockquotes
  blockquote {
    background: #f9f9f9;
    border-left: 0.8em solid #ccc;
    margin: 0;
    padding: 0.8em;
    quotes: "\201C" "\201D" "\2018" "\2019";
    font-size: 1em;
    line-height: 1.2;

    &:before {
      color: #ccc;
      content: open-quote;
      font-size: 3em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }

    p {
      display: inline;
    }
  }
}

.prompt.-prompt-with-features {
  // Styles for images
  section .image-container.with-dimensions {
    margin: 0 auto;
    max-width: var(--image-width);

    .image-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: var(--image-ratio);

      picture {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
}